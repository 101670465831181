export const examples = {
    StnMeta: [
      {'title': 'Basic metadata for a single station.', 'params': '{"sids":"304174"}'},
      {'title': 'Metadata for all stations in a county.', 'params': '{"county":"36001"}'},
      {'title': 'All stations in Delaware Climate Division 1 that have reported snowfall (csv output)',
       'params': '{"climdiv":"DE01","elems":"snow","output":"csv"}'},
      {'title': 'State/valid date range/station name for all stations that are in both the Boston CWA and either CT or RI that reported max or min temperature on June 10, 2021.',
       'params': '{"state":["CT","RI"],"cwa":"box","elems":["maxt","mint"],"date":"2021-06-10","meta":["name","state","valid_daterange"]}'},
    ],
    StnData:[
      {'title': 'Daily max and min temperature, precipitation, snowfall and snow depth for a range of days (csv output).', 
       'params': '{"sid":"304174","sdate":"2009-01-01","edate":"2009-01-10","elems":"1,2,4,10,11","output":"csv"}'},
      {'title': '1991-2020 normal, 1981-2010 normal, and observed 2022 monthly precipitation.',
       'params': '{"sid":"las","sdate":"2022-1","edate":"2022-12","elems":[{"name":"pcpn","interval":[0,1],"duration":1,"normal":"1"},{"name":"pcpn","interval":[0,1],"duration":1,"normal":"81"},{"name":"pcpn","interval":[0,1],"duration":1,"reduce":"sum"}],"meta":"[]"}'},
      {'title': 'Top 10 snowiest seasons (Oct-May) for the period of record with year of occurrence and also period of record mean.',
       'params': '{"sid":"KSYR","sdate":"por-5-31","edate":"por-5-31","meta":"name","elems":[{"name":"snow","interval":[1,0,0],"duration":"std","season_start":[10,1],"maxmissing":1,"reduce":{"add":"mcnt","reduce":"sum"},"smry":{"add":"mcnt,date","n":"10","reduce":"max"},"smry_only":"1"},{"name":"snow","interval":[1,0,0],"duration":"std","season_start":[10,1],"maxmissing":1,"reduce":"sum","smry":"mean","smry_only":"1"}]}'},
      {'title': 'Date of the last "spring" (July 1-June 30) temperature ≤ 28 degrees for the years 1991-2000. Also report what the min temperature was on that date.',
       'params': '{"sid":"118740","sdate":"1991-6-30","edate":"2000-6-30","elems":[{"name":"mint","interval":[1,0,0],"duration":"std","season_start":[7,1],"reduce":{"reduce":"last_le_28","add":"value"},"smry":"min,mean,max"}],"meta":"name,state"}'},
      {'title': 'Accumulated annual precipitation (year-to-date sum every day)',
       'params': '{"sid":"kaus","elems":[{"name":"pcpn","interval":[0,0,1],"duration":"ytd","reduce":"sum"}],"sdate":"2022-12-1","edate":"2023-03-31","meta":"name,state"}'},
      {'title': 'Weekend rainfall (2-day sum every 7 days)',
       'params': '{"sid":"knyc","sdate":"2023-5-7","edate":"2023-10-29","meta":"[]","elems":[{"name":"pcpn","interval":[0,0,7],"duration":2,"reduce":"sum","smry":"pct_gt_0"}]}'},
      {'title': 'Seasonal precipitation and departure from normal (3-month sum every 3 months)',
       'params': '{"sid":"kdca","sdate":"2020-2","edate":"2023-8","meta":"[]","elems":[{"name":"pcpn","interval":[0,3],"duration":3,"reduce":"sum"},{"name":"pcpn","interval":[0,3],"duration":3,"reduce":"sum","normal":"departure"}]}'},
      {'title': 'Snow on ground Christmas Day (same day every year)',
       'params': '{"sid":"304174","sdate":"2000-12-25","edate":"2022-12-25","meta":"[]","elems":[{"name":"snwd","interval":[1,0,0],"duration":1,"smry":"pct_ge_1"}]}'},
      {'title': 'Seasonal (May-Oct) GDD (6-month sum every year)',
       'params': '{"sid":"kbuf","sdate":"2000-10","edate":"2023-10","meta":"[]","elems":[{"name":"gdd","base":45,"interval":[1,0],"duration":6,"reduce":"sum","maxmissing":1}]}'},
      {'title': 'Running 30-year mean annual average temperature (30-year mean every 10 years)',
       'params': '{"sid":"phxthr","sdate":"1930","edate":"2020","elems":[{"name":"avgt","interval":[10],"duration":30,"reduce":"mean","maxmissing":7}],"meta":"name,state"}'},
    ],
    MultiStnData:[
      {'title': 'Annual precipitation totals and count of missing days (max 7) for all stations in a bounding box. Also calculate mean for period.',
       'params': '{"bbox":"-102,48,-98,50","sdate":"2008-01","edate":"2010-12","meta":"name,state,ll","elems":[{"name":"pcpn","interval":[1],"duration":1,"reduce":{"reduce":"sum","add":"mcnt"},"maxmissing":7,"smry":"mean"}]}'
      },
      {'title': 'Daily max temperature data for PA climate division 10, including data network and source information',
      'params': '{"climdiv":"pa10","sdate":"2023-1-1","edate":"2023-10-23","elems":[{"name":"maxt","interval":[0,0,1],"duration":1,"add":"n,s"}],"meta":"name"}'
      },
      {'title': 'Longest runs of consecutive hot days/nights in Maricopa County, AZ in 2023',
      'params': '{"county":"04013","date":"2023-9-30","meta":"name,elev","elems":[{"name":"maxt","interval":[1,0,0],"duration":"std","season_start":[5,1],"reduce":{"reduce":"run_ge_100","n":"1"}},{"name":"mint","interval":[1,0,0],"duration":"std","season_start":[5,1],"reduce":{"reduce":"run_ge_80","n":"1"}}]}'
      },
      {'title': 'First fall frost (32 degrees) and freeze (28 degrees) in northern New York by end of October 2023',
      'params': '{"meta":"name,climdiv","climdiv":"NY07,NY08,NY03","date":"2023-10-26","elems":[{"name":"mint","interval":[1,0,0],"duration":"std","season_start":[9,1],"reduce":"first_le_32"},{"name":"mint","interval":[1,0,0],"duration":"std","season_start":[9,1],"reduce":"first_le_28"}]}'
      }
    ],
    GridData:[
      {'title': 'Map of highest max temperature for June 2012 in Nebraska',
       'params': '{"state":"ne","grid":"1","output":"json","date":"2012-6","elems":[{"name":"maxt","interval":"mly","duration":"mly","reduce":"max"}],"image":{"proj":"lcc","overlays":["county:1:gray","state:2:purple"],"interp":"cspline","width":350,"levels":[90,95,100,105,110]}}'},
    ],
    GridData2:[
      {'title': 'Daily data for a single grid point',
      'params': '{"grid":"nrcc-model","loc":"-77.7,41.8","sdate":"2023-09-01","edate":"2023-10-31","elems":[{"name":"pcpn","interval":[0,0,1],"duration":1}]}'},
      {'title': 'Grid of monthly precipitation sums for Rhode Island (with latitude/longitude grid metadata)',
      'params': '{"grid":"nrcc-model","state":"RI","date":"202309","elems":[{"name":"pcpn","interval":[0,1],"duration":1,"reduce":"sum"}],"meta":"ll"}'},
      {'title': 'Grid of monthly NCEI normal temperatures in a bounding box',
      'params': '{"grid":"ncei-norm:91-20","bbox":"-74,42.75,-73.75,43","elems":[{"name":"avgt","interval":[0,1],"duration":1,"reduce":"mean"}],"sdate":"2023-09","edate":"2023-10"}'},
      {'title': 'Connecticut monthly max maximum temperature and min minimum temperature for October 2023 by county (grid area reductions)',
      'params': '{"grid":"nrcc-nn","sdate":"2023-10","edate":"2023-10","state":"ct","elems":[{"name":"maxt","interval":[0,1],"duration":1,"reduce":"max","area_reduce":"county_max"},{"name":"mint","interval":[0,1],"duration":1,"reduce":"min","area_reduce":"county_min"}]}'},
      {'title': 'Map of highest max temperature for June 2012 in Nebraska (image embedded in JSON)',
      'params': '{"state":"ne","grid":"nrcc-nn","output":"json","date":"2012-6","elems":[{"name":"maxt","interval":[0,1],"duration":1,"reduce":"max"}],"image":{"proj":"lcc","overlays":["county:1:gray","state:2:purple"],"interp":"cspline","width":350,"levels":[90,95,100,105,110]}}'},
       {'title': 'Map of Florida lowest min temperature for January 1985 (just image returned)',
      'params': '{"grid":"nrcc-nn","output":"image","state":"FL","date":"1985-01","elems":[{"name":"mint","interval":[0,1],"duration":1,"reduce":"min"}],"image":{"proj":"lcc","overlays":"state","interp":"cspline","cmap":"Blues","width":350,"levels":[12,17,22,27,32,37]}}'},
      {'title': 'CT state mean average February min temperature for 2000 through 2099 - LOCA weighted mean',
      'params': '{"grid":"loca:wmean:rcp85","state":"ct","sdate":"2000-2","elems":[{"name":"mint","interval":[1,0],"duration":1,"reduce":"mean","area_reduce":"state_mean"}],"output":"json","edate":"2099-2"}'},
      {'title': 'State mean percent of summer days ≥ 90 degrees - LOCA single model',
      'params': '{"grid":"loca:gfdl-cm3:rcp85","state":"va","sdate":"2000-8","edate":"2099-8","elems":[{"name":"maxt","interval":[1,0],"duration":3,"reduce":"pct_ge_90","area_reduce":"state_mean"}]}'},
      {'title': '10-year mean precipitation for February 1-15 for a single grid point (nested daily data)',
      'params': '{"loc":"-75.2,42.5","grid":"nrcc-nn","elems":[{"elem":{"name":"pcpn","interval":[1,0,0],"duration":15,"reduce":"sum"},"interval":[10],"duration":30,"reduce":"mean"}],"edate":"2020-02-15","sdate":"1980-02-15"}'},
      {'title': 'Map of Texas 15-year average summer max temperature (double-nested LOCA weighted mean; image embedded in JSON)',
      'params': '{"elems":[{"elem":{"elem":{"name":"maxt","interval":[0,1],"duration":1,"reduce":"mean"},"interval":[1,0],"duration":3,"reduce":"mean"},"interval":[1],"duration":15,"reduce":"mean"}],"state":"tx","grid":"loca:wmean:rcp45","date":"2040-8","image":{"proj":"3857","width":500,"interp":"none","overlays":["state:1:black","county:0.5:grey"],"levels":[75,80,85,90,95,100]}}'},
    ],
    StnHourly:[
      {'title': 'Houly precipitation for February 22, 2023.',
       'params': '{"sid":"kord","elems":[{"vX":5}],"date":"2023-2-22"}',
      }
    ],
    General:[
      {'title': 'Postal id, name and bounding box for New York State.',
       'generalArea': 'state',
       'params': '{"meta":"id,name,bbox","state":"ny"}'},
      {'title': 'State, FIPS id and name for all counties in a bounding box.',
       'generalArea': 'county',
       'params': '{"meta":"state,id,name","bbox":"-76,41,-75,42"}'},
      {'title': 'Division name and geojson for Nebraska climate division 1.',
       'generalArea': 'climdiv',
       'params': '{"meta":"name,geojson","id":"NE01"}'},
      {'title': 'CWA id, name and bounding box of all CWAs covering any part of Louisiana.',
       'generalArea': 'cwa',
       'params': '{"meta":"id,name,bbox","state":"la"}'},
      {'title': '8-digit HUC id and name of all river basins in a bounding box.',
       'generalArea': 'basin',
       'params': '{"meta":"id,name","bbox":"-75,40,-72,42"}'},
    ],
  }